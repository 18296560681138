// api.js
import axios from "axios";

// Create an Axios instance
const api = axios.create();

// Placeholder for the logout function
let logoutFunction = () => {};

// Function to set the logout function
export const setLogoutFunction = (fn) => {
  logoutFunction = fn;
};

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    // If `config.url` is a relative path, prepend the base URL with the correct port
    if (config.url.startsWith("/")) {
      config.url = getFullUrl(config.url);
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Token is invalid or expired
      logoutFunction(); // Invoke the logout function
    }
    return Promise.reject(error);
  }
);

// Define base URLs for different ports
const baseUrls = {
  default: "https://copartners.in",
  5130: "https://copartners.in:5130",
  5132: "https://copartners.in:5132",
  5134: "https://copartners.in:5134",
  5135: "https://copartners.in:5135",
  5137: "https://copartners.in:5137",
  5009: "https://copartners.in:5009",
  5131: "https://copartners.in:5131",
  5181: "https://copartners.in:5181",
  // Add more ports if necessary
};

// Map relative paths to base URLs and API prefix requirements
const portMap = {
  "/Experts": { baseUrl: baseUrls[5132], useApi: true },
  "/AWSStorage": { baseUrl: baseUrls[5134], useApi: true },
  "/Wallet": { baseUrl: baseUrls[5135], useApi: true },
  "/Subscription": { baseUrl: baseUrls[5009], useApi: true },
  "/Subscriber": { baseUrl: baseUrls[5009], useApi: true },
  "/PaymentGateway": { baseUrl: baseUrls[5009], useApi: true },
  "/RefferalCoupon": { baseUrl: baseUrls[5009], useApi: true },
  "/User": { baseUrl: baseUrls[5131], useApi: true },
  "/SignIn": { baseUrl: baseUrls[5181], useApi: true },
  "/ChatConfiguration": { baseUrl: baseUrls[5137], useApi: true },
};

// Function to map relative URLs to full URLs based on port mappings
export function getFullUrl(relativeUrl) {
  for (const path in portMap) {
    if (relativeUrl.startsWith(path)) {
      const { baseUrl, useApi } = portMap[path];
      const apiPrefix = useApi ? "/api" : "";
      return `${baseUrl}${apiPrefix}${relativeUrl}`;
    }
  }

  // Default base URL if no match is found
  return `${baseUrls.default}/api${relativeUrl}`;
}

export default api;
